<template>
  <div class="card card-custom card-transparent">
    <div v-if="submitStatus === 'ERROR'">
      <b-alert show variant="danger" 
        ><i class="fas fa-exclamation-circle text-white mr-3"></i>

        {{$t('Alert')}}</b-alert
      >
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
        :dir="$t('clan')=='ar'?'rtl':'ltr'"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title mr-5">{{$t('NewSchedule')}}</div>
                  <div class="wizard-desc">{{$t('AddNewSchedule')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                    
                  >
                    <div
                       class="form-group"
                      :class="{ 'form-group--error': $v.driverSchedule.name.$error }"
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('name')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('name')"
                        :class="{ 'form-input--error': $v.driverSchedule.name.$error }"
                        :state="$v.driverSchedule.name.$error === true ? false : null"
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="driverSchedule.name"

                      />
                      
                    </div>

                    <div
                      class="form-group"
                      :class="{
                            'form-group--error': $v.driverSchedule.working_days.$error,
                          }"
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('WorkingDay')}}</label>
                      <div
                          
                        >
                          <vue-select
                            name="drivers"
                            label="full_name"
                            :key="'drivers-field'"
                            :options="createData"
                            :closeOnSelect="false"
                            multiple
                            @input="updateWorkingDays"
                            :class="{
                              'form-select--error': $v.driverSchedule.working_days.$error,
                            }"

                          ></vue-select>
                          
                        </div>
                    </div>

                    <div
                      class="form-group row"

                    >
                    <div class="col-sm-12">
                        <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('WorkingHours')}}</label>
                    </div>
                      
                      <div
                      class="col-sm-6 d-flex mt-5"
                        
                        >
                        
                          <datetime 
                          :class="{
                              'form-select--error': $v.driverSchedule.working_hours_from.$error,
                            }"
                            v-model="driverSchedule.working_hours_from"  
                             type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">

                          </datetime>
                        
                        </div>
                        <div
                      class="col-sm-6 d-flex mt-5"

                        >
                        <span class="mr-4 mt-3">{{$t('To')}}:</span>
                          <datetime  
                          :class="{
                              'form-select--error': $v.driverSchedule.working_hours_to.$error,
                            }"
                            v-model="driverSchedule.working_hours_to"  type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">

                          </datetime>
                         
                        </div>
                    </div>

                    <div
                      class="form-group"
                      
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('workinghourcharges')}} ( {{WorkinghourTypeToggledValue}} )</label>
                      <span class="switch switch-icon switch-sm mb-2" style="float:right" >
                        <label> 
                        <input
                            type="checkbox"
                            name="select"
                            v-model="WorkinghourTypeToggled"
                            @click="setWorkingToggle"
                            
                        />
                        <span></span>
                        </label>
                    </span>

                        <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('Value')"
                        :class="{ 'form-input--error': $v.driverSchedule.working_hour_charges_value.$error }"
                        :state="$v.driverSchedule.working_hour_charges_value.$error === true ? false : null"
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="driverSchedule.working_hour_charges_value"

                      />

                     
                    </div>


                    <div
                      class="form-group"

                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('Nonworkinghourcharges')}} ( {{NoNWorkinghourTypeToggledValue}} )</label>
                      <span class="switch switch-icon switch-sm mb-2" style="float:right" >
                        <label> 
                        <input
                            type="checkbox"
                            name="select"
                            v-model="NoNWorkinghourTypeToggled"
                            @click="setNoNWorkingToggle"
                        />
                        <span></span>
                        </label>
                    </span>

                        <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('Value')"
                        :class="{ 'form-input--error': $v.driverSchedule.non_working_hour_charges_value.$error }"
                        :state="$v.driverSchedule.non_working_hour_charges_value.$error === true ? false : null"
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="driverSchedule.non_working_hour_charges_value"

                      />

                    </div>

                    <div
                      class="form-group"

                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('Drivers')}}</label>
                      <div

                        >
                          <vue-select
                            name="drivers"
                            label="full_name"
                            :key="'drivers-field'"
                            :options="drivers.drivers"
                            :closeOnSelect="false"
                            multiple
                            @input="updateDrivers"

                   
                          ></vue-select>
                        
                        </div>
                    </div>
                    

                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div class="mr-2"></div>
                      <div>
                        <button
                          @click.prevent="submit"
                          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-submit"
                        >
                          {{$t('Submit')}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";


export default {
  name: "CreateQueue",

  data() {
    return {
      driverSchedule:{
        name:'',
        working_days:'',
        working_hours_from:'',
        working_hours_to:'',
        working_hour_charges_mode:'Fixed',
        working_hour_charges_value:'',
        non_working_hour_charges_mode:'Fixed',
        non_working_hour_charges_value:'',
        drivers:'',
        value:'1',

      },
      Schedule:{
          workingdays:[],
          drivers:[],
      },
      WorkinghourTypeToggled:false,
      WorkinghourTypeToggledValue:'Fixed',
      NoNWorkinghourTypeToggled:false,
      NoNWorkinghourTypeToggledValue:'Fixed',
      createData: ['Sudnay','Monday','Tuesday','Wedensday','Thuersday','Friday','Saturday'],
      submitStatus: null,
      drivers:[]
    };
  },

  validations:{
    driverSchedule:{
      name:{
        required
      },
      working_days:{
        required
      },
      working_hours_from:{
        required
      },
      working_hours_to:{
        required
      },
      working_hour_charges_value:{
        required
      },
      non_working_hour_charges_value:{
        required
      },
     
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "ScheduleManager", route: "/admin/schedule-manager" },
      { title: "NewSchedule" },
    ]);

    this.drivers = await this.$store.dispatch("queues/fetchCreateData");
    //console.log(this.createData)

    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on("beforeNext", function (/*wizardObj*/) {});

    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
      setWorkingToggle(){
          if(this.WorkinghourTypeToggled === true){
        this.WorkinghourTypeToggled = false;
        //this.WorkinghourTypeToggledValue='Fixed'
        this.driverSchedule.working_hour_charges_mode='fixed'
        
        

      }
      else if(this.WorkinghourTypeToggled === false){
        this.WorkinghourTypeToggled = true
        //this.WorkinghourTypeToggledValue='Percentage'
        this.driverSchedule.working_hour_charges_mode='percentage'
       
        
      }
      
      },
      setNoNWorkingToggle(){
          if(this.NoNWorkinghourTypeToggled === true){
        this.NoNWorkinghourTypeToggled = false;
        //this.NoNWorkinghourTypeToggledValue='Fixed'
        this.driverSchedule.non_working_hour_charges_mode='fixed'
        
        

      }
      else if(this.NoNWorkinghourTypeToggled === false){
        this.NoNWorkinghourTypeToggled = true
        //this.NoNWorkinghourTypeToggledValue='Percentage'
        this.driverSchedule.non_working_hour_charges_mode='percentage'
       
        
      }
      
      },
    updateDrivers(value) {
      this.Schedule.drivers = value;
      
      
    },
    updateWorkingDays(value){
        this.Schedule.workingdays = value;
        this.driverSchedule.working_days = JSON.stringify(this.Schedule.workingdays)
      console.log(this.driverSchedule.working_days)
        //console.log(this.Schedule.workingdays)
    },
    async submit() {
      //this.driverSchedule = this.s
     // var dateFrom = new Date(this.driverSchedule.working_hours_from)
      //var dateTo = new Date(this.driverSchedule.working_hours_to)
     /* var dateFromStyle = (dateFrom.getHours()>12?dateFrom.getHours()-12:dateFrom.getHours() )
      +':'+(dateFrom.getMinutes()<10?'0'+dateFrom.getMinutes():dateFrom.getMinutes())+ (dateFrom.getHours()>=12?' PM':' AM')

      var dateToStyle = (dateTo.getHours()>12?dateTo.getHours()-12:dateTo.getHours() )
      +':'+(dateTo.getMinutes()<10?'0'+dateTo.getMinutes():dateTo.getMinutes())+ (dateTo.getHours()>=12?' PM':' AM')*/

      //console.log(this.Schedule.drivers)
      
      //this.driverSchedule.working_hours_from = dateFrom
      //this.driverSchedule.working_hours_to = dateTo
        //console.log('----')
      //console.log(this.Schedule.drivers)
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        //console.log('error')
      } else {
      var string = ''
      if(this.Schedule.drivers.length ===1){
        //console.log('-------')
        string  = this.Schedule.drivers[0].id
       // console.log('--------------')
      }
      else{

    console.log('pass')
      for(var s=0; s<this.Schedule.drivers.length; s++){
       if(s==0){
        string = this.Schedule.drivers[s].id
       }else{
         string = string + ',' + this.Schedule.drivers[s].id
       }
       
      }
        }
        
      this.driverSchedule.drivers = string.toString()
       //console.log(string)
      //console.log(this.Schedule.drivers)
      //console.log(this.Schedule.drivers[0])
        await this.$store.dispatch("dschedule/create", this.driverSchedule);

        Swal.fire({
          title: "",
          text: "The queue has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push({ name: "ScheduleManager" });
        //this.$store.dispatch("queues/loadAll");

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
     }
    },
  },
  
  
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>